<template>
  <div class="mb-3">
    <button type="button" class="btn btn-danger" v-if="selectedClass()" @click="cancelEnrollment()">Cancel enrollment</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    cancelEnrollment () {
      if (!confirm('Are you sure you want to cancel?')) {
        return
      }
      this.$store.commit('selectStudents', { students: [] })
      this.$store.commit('selectClass', { class: null })
      this.$router.replace({ name: 'home' })
    },
    selectedClass () {
      return this.$store.getters.selectedClass
    }
  }
})
</script>
