
import { defineComponent } from 'vue'
import Student from '@/types/student'
import CancelEnrollment from '@/components/CancelEnrollment.vue'

export default defineComponent({
  components: { CancelEnrollment },
  data () {
    return {
      selectedStudentIds: [] as number[],
      students: [] as Student[]
    }
  },
  async mounted () {
    // INFO: access to this route requires authentication, so if the user is not logged in, they will first be asked to log in
    // if account has no students, redirect to add students
    const response = await this.$api.getStudents()
    if (response.students) {
      this.students = response.students
    }
    if (this.students.length < 1) {
      this.$router.replace({ name: 'student_list' })
    }
    if (this.$store.getters.selectedStudents.length > 0) {
      this.$router.push({ name: 'enrollment_agreement' })
    }
  },
  computed: {
    selectedClassName () {
      if (!this.$store.getters.selectedClass) {
        return this.$router.replace({ name: 'home' })
      }
      return this.$store.getters.selectedClass.courseName
    }
  },
  methods: {
    selectStudents () {
      const selectedStudents = this.students.filter((student) => { return this.selectedStudentIds.includes(student.id) })
      if (selectedStudents.length < 1) {
        this.$store.commit('error', { error: 'You must select at least one student.' })
      } else {
        this.$store.commit('selectStudents', { students: selectedStudents })
        this.$router.push({ name: 'enrollment_agreement' })
      }
    }
  }
})
